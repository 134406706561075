/**
 * Form service API
 * This is the documentation of the CaaS cloud form filler service API.<br /><br /> **This document is Thieme internal.**
 *
 * The version of the OpenAPI document: current
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Permission = 'crud-user' | 'r-user' | 'w-user' | 'd-user' | 'r-admission' | 'w-admission' | 'd-admission';

export const Permission = {
    CrudUser: 'crud-user' as Permission,
    RUser: 'r-user' as Permission,
    WUser: 'w-user' as Permission,
    DUser: 'd-user' as Permission,
    RAdmission: 'r-admission' as Permission,
    WAdmission: 'w-admission' as Permission,
    DAdmission: 'd-admission' as Permission
};


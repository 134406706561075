import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { map, take } from 'rxjs'
import { AuthFacade } from '../../components/auth/+state/auth.facade'

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  auth = inject(AuthFacade)

  constructor(private readonly router: Router) {}

  canActivate() {
    return this.auth.sessionMetaData$.pipe(
      take(1),
      map((session) => {
        const isLoggedIn = !!session
        if (isLoggedIn) {
          const redirectPage = window.sessionStorage.getItem('current-page') ?? '/home'
          this.router.navigateByUrl(redirectPage)
          return false
        } else {
          return true
        }
      }),
    )
  }
}

import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() public appAutoFocus!: boolean

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this.appAutoFocus) {
      setTimeout(() => {
        this.el.nativeElement.focus()
      }, 100)
    }
  }
}

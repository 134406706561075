import { Component, inject, OnInit } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TokenExpiredScreenComponent } from './token-expired-screen.component'
import { PasswordUpdatedScreenComponent } from './password-updated-screen.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { PasswordGuidelinesComponent } from '../password-guidelines/password-guidelines.component'
import { AuthFacade } from '../+state/auth.facade'
import { AsyncPipe } from '@angular/common'

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    TranslateModule,
    TokenExpiredScreenComponent,
    PasswordUpdatedScreenComponent,
    PasswordGuidelinesComponent,
    MatProgressSpinnerModule,
    AsyncPipe,
  ],
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3">
        @if ((isTokenValid$ | async) === undefined) {
          <div class="m-flex-center">
            <mat-spinner></mat-spinner>
          </div>
        } @else if ((isTokenValid$ | async) === false) {
          <app-token-expired-screen></app-token-expired-screen>
        } @else if (isPasswordUpdated$ | async) {
          <app-password-updated-screen></app-password-updated-screen>
        } @else {
          <section class="card" data-testid="reset-password-form">
            <h3 class="title heading-m m-semibold">{{ 'auth.resetPassword.title' | translate }}</h3>

            <p class="text-m">{{ 'auth.resetPassword.description' | translate }}</p>

            <app-password-guidelines
              (passwordOutput)="updatePasswordString($event)"
              (isValid)="checkPassword($event)"
              [email]="email"
            ></app-password-guidelines>

            <button
              [disabled]="!passwordIsValid"
              class="btn-send btn-primary width-100"
              (click)="handleSetPassword()"
              tabindex="-1"
            >
              {{ 'auth.resetPassword.submitButtonLabel' | translate }}
            </button>
          </section>
        }
      </div>
    </div>
  `,
  styles: `
    .card {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      max-width: 480px;
      margin-inline: auto;
    }

    cc-input-field {
      width: 100%;
    }

    .password-rule-title {
      margin-bottom: 8px;
    }

    .password-rule-wrapper {
      padding: 10px;
      border-radius: 4px;
      background-color: var(--color-blue-50);
      color: var(--color-blue-700);
    }

    .password-rule {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .icon {
      zoom: 0.75;
    }

    app-icon-circle-check-filled {
      fill: var(--color-green-600);
    }

    app-icon-circle-close-filled {
      fill: var(--color-blue-400);
    }
  `,
})
export class ResetPasswordComponent implements OnInit {
  email = ''
  token = ''
  password = ''
  passwordVisible = false
  passwordIsValid = false
  auth = inject(AuthFacade)
  isTokenValid$ = this.auth.isTokenValid$
  isPasswordUpdated$ = this.auth.isPasswordUpdated$

  constructor(
    private route: ActivatedRoute,
    protected readonly router: Router,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email']
      this.token = params['token']
    })
    this.auth.checkTokenValidity({ email: this.email, token: this.token })
  }

  handleSetPassword() {
    if (!this.email || !this.token || !this.passwordIsValid) {
      return
    }
    this.auth.resetPassword({ tokenCredentials: { email: this.email, token: this.token }, newPassword: this.password })
  }

  checkPassword(value: boolean) {
    this.passwordIsValid = value
  }

  updatePasswordString(value: string) {
    this.password = value
  }
}

import { ActionReducer, MetaReducer } from '@ngrx/store'
import * as AuthActions from '../components/auth/+state/auth.actions'
import { Action } from '@ngrx/store'

export function clearState<State extends Record<string, unknown>>(
  reducer: ActionReducer<State, Action>,
): ActionReducer<State, Action> {
  return function (state: State | undefined, action: Action): State {
    if (action.type === AuthActions.logoutSuccess.type) {
      state = undefined
    }

    return reducer(state, action)
  }
}

export const metaReducers: MetaReducer<Record<string, unknown>, Action>[] = [clearState]

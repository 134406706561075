import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-invoke-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invoke-error.component.html',
  styleUrl: './invoke-error.component.scss',
})
export class InvokeErrorComponent {
  constructor() {
    throw new Error('Oops. Something went wrong.')
  }
}

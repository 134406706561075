/**
 * Form service API
 * This is the documentation of the CaaS cloud form filler service API.<br /><br /> **This document is Thieme internal.**
 *
 * The version of the OpenAPI document: current
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Role = 'admin' | 'manager' | 'keyuseradmission' | 'admission';

export const Role = {
    Admin: 'admin' as Role,
    Manager: 'manager' as Role,
    Keyuseradmission: 'keyuseradmission' as Role,
    Admission: 'admission' as Role
};


import { createAction, props } from '@ngrx/store'
import { User } from '../../../generated'
import { Sort } from '@angular/material/sort'
import { Update } from '@ngrx/entity'
import { HttpErrorResponse } from '@angular/common/http'

export const initUserManagement = createAction('[UserManagement Page] Init')

export const loadUserManagementSuccess = createAction(
  '[UserManagement/API] Load UserManagement Success',
  props<{ users: User[] }>(),
)

export const loadUserManagementFailure = createAction(
  '[UserManagement/API] Load UserManagement Failure',
  props<{ error: HttpErrorResponse }>(),
)

export const createUser = createAction('[UserManagement Page] Create User', props<{ user: User }>())

export const createUserSuccess = createAction('[UserManagement/API] Create User Success', props<{ user: User }>())

export const createUserFailure = createAction(
  '[UserManagement/API] Create User Failure',
  props<{ error: HttpErrorResponse }>(),
)

export const updateUser = createAction('[UserManagement Page] Update User', props<{ user: User }>())

export const updateUserSuccess = createAction('[UserManagement/API] Update User Success', props<{ user: User }>())

export const updateUserFailure = createAction(
  '[UserManagement/API] Update User Failure',
  props<{ error: HttpErrorResponse }>(),
)

export const deleteUser = createAction('[UserManagement Page] Delete User', props<{ user: User }>())

export const deleteUserSuccess = createAction('[UserManagement/API] Delete User Success', props<{ user: User }>())

export const deleteUserFailure = createAction(
  '[UserManagement/API] Delete User Failure',
  props<{ error: HttpErrorResponse }>(),
)

export const sortUsers = createAction('[UserManagement] Sort Users', props<{ sort: Sort }>())

export const filterUsers = createAction('[UserManagement] Filter Users', props<{ filter: string }>())

export const doNothing = createAction('[UserManagement] Do Nothing', props<{ message: string; effect?: string }>())

export const patchEnabledStatus = createAction('[UserManagement] Enable/Disable User', props<{ user: User }>())

export const patchEnabledStatusSuccess = createAction(
  '[UserManagement] Enable/Disable User Success',
  props<{ user: Update<User> }>(),
)

export const patchEnabledStatusFailure = createAction(
  '[UserManagement] Enable/Disable User Failure',
  props<{ error: Error }>(),
)

import { Component, inject } from '@angular/core'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { IconEditFilledComponent } from '@client-workspace/icons/edit_filled.component'
import { EMAIL_REGEX } from '../../../../-shared/utils/validation-regex.const'
import { Role, User } from '../../../../generated'
import { I18nUtilityService } from '../../../../-shared/services/i18n.utility-service'
import { UserManagementFacade } from '../../+state/user-management.facade'
import { IconPlusFilledComponent } from '@client-workspace/icons/plus_filled.component'

@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogClose,
    MatDialogModule,
    IconCloseFilledComponent,
    IconEditFilledComponent,
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatCheckboxModule,
    IconPlusFilledComponent,
  ],
  providers: [UserManagementFacade],
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.scss',
})
export class UserFormComponent {
  user: User = inject(MAT_DIALOG_DATA)
  userManagementFacade = inject(UserManagementFacade)
  apiError = ''

  userEditFormGroup: FormGroup
  nameValidators = [Validators.required, Validators.minLength(1), Validators.maxLength(256)]
  firstName = new FormControl('', this.nameValidators)
  lastName = new FormControl('', this.nameValidators)
  email = new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)])
  roles = new FormControl([], [Validators.required])

  i18nUtility = I18nUtilityService
  roleList = Object.values(Role)
  userUpdateStatus$ = this.userManagementFacade.userUpdateStatus$
  requestSent = false

  constructor(private dialog: MatDialogRef<UserFormComponent>) {
    this.userEditFormGroup = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      roles: this.roles,
    })
    const { firstName, lastName, email, roles } = this.user
    this.userEditFormGroup.setValue({ firstName, lastName, email, roles })
  }

  updateUser() {
    if (this.user) {
      this.requestSent = true
      const data = this.userEditFormGroup.getRawValue()
      if (this.user.id) {
        this.userManagementFacade.update({ id: this.user.id, ...data })
      } else {
        this.userManagementFacade.create(data)
      }
      this.userUpdateStatus$.subscribe(({ updating, error }) => {
        if (!updating && !error) {
          this.dialog.close(this.user)
        }
        if (error) {
          this.email.setErrors({ api: true })
          this.apiError = error?.message
        }
        this.requestSent = false
      })
    }
  }
}

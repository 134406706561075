import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthFacade } from '../../components/auth/+state/auth.facade'
import { take } from 'rxjs'

@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective {
  user$ = this.authFacade.user$
  permission: string | string[] = ''
  isHidden = true

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Element>,
    private authFacade: AuthFacade,
  ) {}

  @Input()
  set appHasPermission(val: string | string[]) {
    this.permission = val
    this.updateView()
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef)
        this.isHidden = false
      }
    } else {
      this.isHidden = true
      this.viewContainer.clear()
    }
  }

  private checkPermission() {
    let permissions: string[] | undefined = []
    this.user$.pipe(take(1)).subscribe((user) => (permissions = user?.permissions))

    if (!permissions) {
      return false
    }

    if (Array.isArray(this.permission)) {
      return this.permission.every((perm) => permissions?.includes(perm))
    } else {
      return permissions.includes(this.permission)
    }
  }
}

export const REALM_NAME = 'default'
type NestedItem<T> = T & { items?: Array<NestedItem<T>> }

export function getRealmNameFromUrl(url: string) {
  const regex = /\/realms\/([\w|\d|-]+)/gm
  const realmMatches = regex.exec(url)
  let realmName = window.sessionStorage.getItem('realm') ?? REALM_NAME
  if (realmMatches && realmMatches[1]) {
    realmName = realmMatches[1]
  }
  return realmName
}

export function replaceDeeplyNestedObj<T>(arr: Array<NestedItem<T>>, obj: T, key: keyof T): void {
  for (const el of arr) {
    if (el[key] === obj[key]) {
      arr[arr.indexOf(el)] = { ...el, ...obj }
      return
    } else if (el.items && Array.isArray(el.items)) {
      replaceDeeplyNestedObj(el.items, obj, key)
    }
  }
}

export function deleteDeeplyNestedObj<T>(arr: Array<NestedItem<T>>, obj: T, key: keyof T): void {
  for (const el of arr) {
    if (el[key] === obj[key]) {
      arr.splice(arr.indexOf(el), 1)
      return
    } else if (el.items && Array.isArray(el.items)) {
      deleteDeeplyNestedObj(el.items, obj, key)
    }
  }
}

export function flatten<T extends object>(someArray: Array<NestedItem<T> | T>, result: T[] = []): T[] {
  someArray.forEach((item) => {
    if ('items' in item && Array.isArray(item.items)) {
      flatten(item.items, result)
    } else {
      result.push(item as T)
    }
  })
  return result
}

import { inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'

import * as AdmissionsActions from './admissions.actions'
// import * as AdmissionsFeature from './admissions.reducer'
import * as AdmissionsSelectors from './admissions.selectors'
import { FhirQuestionnaireResponse } from '@client-workspace/fhir-engine'

@Injectable()
export class AdmissionsFacade {
  private readonly store = inject(Store)

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(AdmissionsSelectors.selectAdmissionsLoaded))
  allAdmissions$ = this.store.pipe(select(AdmissionsSelectors.selectAllAdmissions))
  dataSource$ = this.store.pipe(select(AdmissionsSelectors.selectDataSource))
  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(AdmissionsActions.initAdmissions())
  }

  selectAdmission(id: string) {
    return this.store.pipe(select(AdmissionsSelectors.selectAdmission(id)))
  }

  updateQuestionnaireResponseInAdmissionById(id: string, updatedQuestionnaireResponse: FhirQuestionnaireResponse) {
    this.store.dispatch(
      AdmissionsActions.updateAdmission({ id, updatedQuestionnaireResponse: updatedQuestionnaireResponse }),
    )
  }
}

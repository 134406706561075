import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CanvasComponent } from './canvas/canvas.component'
import { PropertiesEditorComponent } from './properties-editor/properties-editor.component'
import { BuilderFacade } from './+state/builder.facade'
import { QuestionnaireItem } from './models/fhir-interfaces'
import { HttpClient } from '@angular/common/http'
import { Option } from '@client-workspace/custom-components'
@Component({
  selector: 'app-questionnaire-builder',
  standalone: true,
  imports: [CommonModule, CanvasComponent, PropertiesEditorComponent],
  templateUrl: './questionnaire-builder.component.html',
  styleUrl: './questionnaire-builder.component.scss',
})
export class QuestionnaireBuilderComponent implements OnInit, OnDestroy {
  @Input() id!: string
  builderFacade = inject(BuilderFacade)
  questions: QuestionnaireItem[] = []
  properties: { name: string; title: string; id: string | undefined } = { name: '', title: '', id: undefined }
  editQuestion: QuestionnaireItem | undefined = undefined
  questionnaireName = ''
  questionnaireTitle = ''
  enableWhenQuestions: Option[] = []

  constructor(protected httpClient: HttpClient) {
    this.builderFacade.questions$.subscribe((questions: QuestionnaireItem[]) => {
      this.questions = questions
    })

    this.builderFacade.properties$.subscribe((properties) => {
      this.properties = properties
    })

    this.builderFacade.editQuestion$.subscribe((question) => {
      this.editQuestion = structuredClone(question)
    })

    this.builderFacade.builderState$.subscribe((builderState) => {
      this.questionnaireName = builderState.name
      this.questionnaireTitle = builderState.title
    })

    this.builderFacade.selectEnableWhenQuestions$.subscribe((enableWhenQuestions) => {
      this.enableWhenQuestions = enableWhenQuestions
        .filter((el) => el.linkId !== this.editQuestion?.linkId)
        .map((q) => ({
          value: q.linkId,
          label: q.text,
        }))
    })
  }

  ngOnInit(): void {
    this.loadQuestionnaire(this.id)
  }

  async loadQuestionnaire(id: string) {
    try {
      const data = await fetch(`/services/crud-playground/questionnaires/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const questionnaire = await data.json()
      if (questionnaire.body) {
        this.builderFacade.loadQuestionnaire(questionnaire.id, questionnaire.body)
      }
    } catch (error) {
      console.log(error)
    }
  }

  ngOnDestroy(): void {
    this.builderFacade.resetState()
  }

  handleSaveQuestions(questions: QuestionnaireItem[]) {
    this.builderFacade.setQuestions(questions)
  }

  async handleUpdateQuestionnaire() {
    if (this.properties.id) {
      this.builderFacade.updateQuestionnaire()
    }
  }

  async handleSaveQuestionnaire() {
    this.builderFacade.saveQuestionnaire()
  }

  handleDeleteQuestion(item: QuestionnaireItem) {
    this.builderFacade.deleteQuestion(item)
  }

  handleUpdateProperties({ name, title }: { name: string; title: string }) {
    this.builderFacade.updateProperties({ name, title })
  }

  handleUpdateQuestion(item: QuestionnaireItem) {
    this.builderFacade.updateQuestion(item)
  }
  handleEditQuestion(item: QuestionnaireItem) {
    this.builderFacade.editQuestion(item)
  }
}

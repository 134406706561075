import { createFeatureSelector, createSelector } from '@ngrx/store'
import { USER_MANAGEMENT_FEATURE_KEY, userManagementAdapter, UserManagementState } from './user-management.reducer'
import { User } from '../../../generated'
import { UserRepository } from './user-management.repository'

// Lookup the 'UserManagement' feature state managed by NgRx
export const selectUserManagementState = createFeatureSelector<UserManagementState>(USER_MANAGEMENT_FEATURE_KEY)

const { selectAll, selectEntities } = userManagementAdapter.getSelectors()

export const selectUserManagementLoaded = createSelector(
  selectUserManagementState,
  (state: UserManagementState) => state.loaded,
)

export const selectUserManagementError = createSelector(
  selectUserManagementState,
  (state: UserManagementState) => state.error,
)

export const selectAllUserManagement = createSelector(selectUserManagementState, (state: UserManagementState) =>
  selectAll(state),
)

export const selectUserManagementEntities = createSelector(selectUserManagementState, (state: UserManagementState) =>
  selectEntities(state),
)

export const selectSelectedId = createSelector(
  selectUserManagementState,
  (state: UserManagementState) => state.selectedId,
)

export const selectUserUpdateStatus = createSelector(selectUserManagementState, (state: UserManagementState) => ({
  updating: state.updating,
  error: state.updateError,
}))

export const selectEntity = createSelector(selectUserManagementEntities, selectSelectedId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined,
)

export const selectSort = createSelector(selectUserManagementState, (state: UserManagementState) => state.sort)

export const selectFilter = createSelector(selectUserManagementState, (state: UserManagementState) => state.filter)

export const selectFilteredUsers = createSelector(selectUserManagementEntities, selectFilter, (entities, filter) => {
  return filter
    ? UserRepository.filterListByString(filter, Object.values(entities) as User[])
    : (Object.values(entities) as User[])
})

export const selectFilteredAndSortedUsers = createSelector(selectFilteredUsers, selectSort, (users, sort) =>
  sort ? UserRepository.sortData(sort, users) : users,
)

import { Injectable, inject } from '@angular/core'
import { select, Store } from '@ngrx/store'

import * as BuilderActions from './builder.actions'
import { Questionnaire, QuestionnaireItem } from '../models/fhir-interfaces'
import * as BuilderSelectors from './builder.selectors'

@Injectable()
export class BuilderFacade {
  private readonly store = inject(Store)
  questions$ = this.store.pipe(select(BuilderSelectors.questions))
  editQuestion$ = this.store.pipe(select(BuilderSelectors.editQuestion))
  properties$ = this.store.pipe(select(BuilderSelectors.properties))
  builderState$ = this.store.pipe(select(BuilderSelectors.selectBuilderState))
  selectEnableWhenQuestions$ = this.store.pipe(select(BuilderSelectors.selectEnableWhenQuestions))

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(BuilderActions.initBuilder())
  }

  addQuestion(question: QuestionnaireItem) {
    this.store.dispatch(BuilderActions.addQuestion({ question }))
  }

  updateQuestion(question: QuestionnaireItem) {
    this.store.dispatch(BuilderActions.updateQuestion({ question }))
  }

  deleteQuestion(question: QuestionnaireItem) {
    this.store.dispatch(BuilderActions.deleteQuestion({ question }))
  }

  setQuestions(questions: QuestionnaireItem[]) {
    this.store.dispatch(BuilderActions.setQuestions({ questions }))
  }

  editQuestion(question: QuestionnaireItem) {
    this.store.dispatch(BuilderActions.editQuestion({ question }))
  }

  saveQuestionnaire() {
    this.store.dispatch(BuilderActions.saveQuestionnaire())
  }

  updateProperties(properties: { name: string; title: string }) {
    this.store.dispatch(BuilderActions.updateProperties(properties))
  }

  loadQuestionnaire(id: string, questionnaire: Questionnaire) {
    this.store.dispatch(BuilderActions.loadQuestionnaire({ id, questionnaire }))
  }

  updateQuestionnaire() {
    this.store.dispatch(BuilderActions.updateQuestionnaire())
  }

  resetState() {
    this.store.dispatch(BuilderActions.resetState())
  }
}

import { Component, computed, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionComponent } from '../../../questionnaire-builder/question/question.component'
import { QuestionnaireItem } from '../../../questionnaire-builder/models/fhir-interfaces'

@Component({
  selector: 'app-questionnaire-renderer',
  standalone: true,
  imports: [CommonModule, QuestionComponent],
  templateUrl: './questionnaire-renderer.component.html',
  styleUrl: './questionnaire-renderer.component.scss',
})
export class QuestionnaireRendererComponent {
  @Input() questions: QuestionnaireItem[] = []
  @Input() title = ''

  // isQuestionEnabled = computed(() => {
  //   if (!question.enableWhen || !question.enableWhen.length) {
  //     return true
  //   }
  //   return false

  //   // if (showCount()) {
  //   //   return `The count is ${count()}.`;
  //   // } else {
  //   //   return 'Nothing to see here!';
  //   // }
  // })

  // isQuestionEnabled(question: QuestionnaireItem) {
  //   // console.log('isQuestionEnabled')
  //   if (!question.enableWhen || !question.enableWhen.length) {
  //     return true
  //   }
  //   return false
  // }
}

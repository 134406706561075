import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { IconCircleErrorFilledComponent } from '@client-workspace/icons/circle_error_filled.component'

@Component({
  selector: 'app-unknown-admissions',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconCircleErrorFilledComponent],
  template: ` <div class="row">
    <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3">
      <section class="card">
        <header class="m-text-center">
          <app-icon-circle-error-filled class="icon m-centered"></app-icon-circle-error-filled>

          <h3 class="heading-m m-semibold">{{ 'admissions.error.headline' | translate }}</h3>
        </header>
        <div class="text-m">
          <p>
            {{ 'admissions.error.unknownPersonHint' | translate }}
          </p>
          <ul>
            @if (!person.firstName) {
              <li>{{ 'admissions.error.firstNameMissing' | translate }}</li>
            }
            @if (!person.lastName) {
              <li>{{ 'admissions.error.lastNameMissing' | translate }}</li>
            }
            @if (!person.birthDay) {
              <li>{{ 'admissions.error.birthdayMissing' | translate }}</li>
            }
          </ul>
        </div>

        <button class="btn-send btn-primary width-100" (click)="handleBtn()">
          {{ 'admissions.error.buttonLabel' | translate }}
        </button>
      </section>
    </div>
  </div>`,
  styles: `
    .card {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 80px;
      max-width: 480px;
      margin-inline: auto;
    }

    ul {
      list-style: disc;
      list-style-position: inside;
    }

    .icon {
      fill: var(--color-red-700);
      zoom: 2.5;
    }
  `,
})
export class UnknownAdmissionComponent {
  person = { firstName: '', lastName: '', birthDay: '' }

  constructor(private router: Router) {
    const { person } = this.router.getCurrentNavigation()?.extras.state || {}
    if (person) {
      this.person = person
    }
  }

  handleBtn() {
    console.log('handleBtn')
  }
}

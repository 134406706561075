import { Component, inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Questionnaire, Workflow } from '../../questionnaire-builder/models/fhir-interfaces'
import { CdkDragDrop, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { WorkflowEngineFacade } from './+state/workflow-engine.facade'

interface WorkflowUiItem {
  id: string
  type: string
  title: string
}

interface ExtractedQuestionnaire {
  id: string
  name: string
  title: string
}

@Component({
  selector: 'app-workflow-engine',
  standalone: true,
  imports: [CommonModule, CdkDropList, DragDropModule, InputFieldComponent],
  templateUrl: './workflow-engine.component.html',
  styleUrl: './workflow-engine.component.scss',
})
export class WorkflowEngineComponent implements OnInit {
  workflowFacade = inject(WorkflowEngineFacade)
  extractedQuestionnaire: ExtractedQuestionnaire[] = []
  questionnaires: Questionnaire[] = []
  workflowItems: WorkflowUiItem[] = []
  workflowTitle = ''
  workflowName = ''
  savedQuestionnaire = false

  ngOnInit(): void {
    this.getQuestionnaires()
  }

  async getQuestionnaires() {
    const response = await fetch(`/services/crud-playground/questionnaires`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    this.questionnaires = data.map(({ id, body }: { id: string; body: Questionnaire }) => ({ id, ...body }))

    this.extractedQuestionnaire = this.extractQuestionnaires(this.questionnaires)
  }

  onDrop(event: CdkDragDrop<ExtractedQuestionnaire[]>): void {
    const itemToAdd: WorkflowUiItem = { id: '', type: 'group', title: '' }
    const droppedItem: ExtractedQuestionnaire = event.item.data
    itemToAdd.id = droppedItem.id
    itemToAdd.type = 'group'
    itemToAdd.title = droppedItem.title

    this.workflowItems.push(itemToAdd)
  }

  saveWorkflow() {
    if (!this.workflowItems.length) {
      return
    }

    const allItemsFromReal = this.workflowItems.reduce(
      (questionnaires, workflowItem) => {
        const foundItem = this.questionnaires.find((questionnaire) => workflowItem.id === questionnaire.id)
        if (foundItem) {
          questionnaires.push(foundItem)
        }
        return questionnaires
      },
      [] as typeof this.questionnaires,
    )

    this.questionnaires.filter((q) => this.workflowItems.filter((wi) => wi.id === q.id))
    const workflow: Workflow = {
      language: 'de-DE',
      resourceType: 'Questionnaire',
      status: 'draft',
      title: this.workflowTitle,
      name: this.workflowName,
      items: allItemsFromReal,
    }

    this.workflowFacade.saveWorkflow(workflow)
    this.savedQuestionnaire = true
  }

  extractQuestionnaires(questionnaires: Questionnaire[]): ExtractedQuestionnaire[] {
    return questionnaires.map((entry) => ({
      id: entry.id ?? '',
      name: entry.name,
      title: entry.title,
    }))
  }
}

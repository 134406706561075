<section class="list-admissions">
  @if (dataSource$|async) {
    <section class="list-admissions-inner">
      <h2 class="heading-m m-semibold">
        {{ 'admissions.header'|translate }}
      </h2>
      <table mat-table [dataSource]="((dataSource$ | async) || [])">
        <caption>&nbsp;</caption>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- ['status', 'date', 'fn', 'ln', 'ward', 'insuranceName', 'actions' ] -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.status'|translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.status }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.date'|translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.date | date }}</td>
        </ng-container>

        <ng-container matColumnDef="fn">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.firstName'|translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.fn }}</td>
        </ng-container>

        <ng-container matColumnDef="ln">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.lastName'|translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.ln }}</td>
        </ng-container>

        <ng-container matColumnDef="ward">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.ward'|translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.ward }}</td>
        </ng-container>

        <ng-container matColumnDef="insuranceName">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.insuranceName'|translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.insuranceName }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'admissions.tableHeader.actions'|translate }}</th>
          <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/admissions/process', element.actions]">
                <app-icon-edit-outline></app-icon-edit-outline>
              </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </section>
  }
</section>

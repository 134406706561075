import { createAction, props } from '@ngrx/store'
import { AuthEntity } from './auth.models'
import { AuthenticatedUser, ResolveUpdatePasswordAction, SessionMetaData, TokenCredentials } from '../../../generated'

export const initAuth = createAction('[Auth Page] Init')

export const loadAuthSuccess = createAction('[Auth/API] Load Auth Success', props<{ auth: AuthEntity[] }>())

export const loadAuthFailure = createAction('[Auth/API] Load Auth Failure', props<{ error: Error }>())

export const login = createAction('[Auth] Login', props<{ user: string; password: string; realm: string }>())

export const loginSuccess = createAction('[Auth] Login Success', props<{ auth: SessionMetaData }>())

export const loginFailure = createAction('[Auth] Login Failure', props<{ error: Error }>())

export const forgotPassword = createAction('[Auth] Forgot Password', props<{ email: string; realm: string }>())

export const forgotPasswordSuccess = createAction('[Auth] Forgot Password Success')

export const forgotPasswordFailure = createAction('[Auth] Forgot Password Failure', props<{ error: Error }>())

export const resetPassword = createAction('[Auth] Reset Password', props<ResolveUpdatePasswordAction>())

export const resetPasswordSuccess = createAction('[Auth] Reset Password Success')

export const resetPasswordFailure = createAction('[Auth] Reset Password Failure', props<{ error: Error }>())

export const resetPasswordRequested = createAction('[Auth] Reset Password Requested')

export const checkTokenValidity = createAction('[Auth] Check Token Validity', props<TokenCredentials>())

export const checkTokenValiditySuccess = createAction('[Auth] Check Token Validity Success')

export const checkTokenValidityFailure = createAction('[Auth] Check Token Validity Failure', props<{ error: Error }>())

export const getAuthenticatedUser = createAction('[Auth] Get Authenticated User')

export const getAuthenticatedUserSuccess = createAction(
  '[Auth] Get Authenticated User Success',
  props<{ user: AuthenticatedUser }>(),
)

export const getAuthenticatedUserFailure = createAction(
  '[Auth] Get Authenticated User Failure',
  props<{ error: Error }>(),
)

export const refreshToken = createAction('[Auth] Refresh Token')

export const refreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{ sessionMetaData: SessionMetaData }>(),
)

export const refreshTokenFailure = createAction('[Auth] Refresh Token Failure', props<{ error: Error }>())

export const deleteSession = createAction('[Auth] Delete Session')

export const deleteSessionSuccess = createAction('[Auth] Delete Session Success')

export const deleteSessionFailure = createAction('[Auth] Delete Session Failure', props<{ error: Error }>())

export const logout = createAction('[Auth] Logout')

export const logoutSuccess = createAction('[Auth] Logout Success')

export const logoutFailure = createAction('[Auth] Logout Failure', props<{ error: Error }>())

export const restoreRealm = createAction('[Auth] Restore Realm', props<{ before: string; after: string }>())

export const correctRealm = createAction('[Auth] Correct Realm', props<{ message: string; effect?: string }>())

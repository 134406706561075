<div class="accent-flag">
  <div class="top"></div>
  <div class="middle"></div>
  <div class="bottom"></div>
</div>

<app-header />

<section class="container">
  @if (user$ | async) {
    <menu class="main-menu app-menu">
      <a [routerLink]="['/home']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="is-current">
        <app-icon-home-outline class="icon"></app-icon-home-outline>
        {{ 'nav.home' | translate }}
      </a>
      <a
        *appHasPermission="'r-user'"
        [routerLink]="['/user-management']"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="is-current"
      >
        <app-icon-user-outline class="icon"> </app-icon-user-outline>

        {{ 'nav.userManagement' | translate }}
      </a>
      @if (isQuestionnaireVisible) {
        <a
          [routerLink]="['/questionnaire-builder']"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="is-current"
        >
          <app-icon-message-typing-outline class="icon" e></app-icon-message-typing-outline>
          {{ 'nav.questionnaires' | translate }}
        </a>

        <a
          [routerLink]="['/workflow-engine']"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="is-current"
        >
          <app-icon-treatment-outline class="icon"></app-icon-treatment-outline>

          {{ 'nav.workflow-engine' | translate }}
        </a>

        <a
          *appHasPermission="'r-admission'"
          [routerLink]="['/admissions/list']"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="is-current"
        >
          <app-icon-group-outline class="icon"></app-icon-group-outline>

          {{ 'nav.admissions' | translate }}
        </a>

        <a
          [routerLink]="['/invoke-error']"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="is-current"
        >
          <app-icon-circle-error-outline class="icon"></app-icon-circle-error-outline>

          {{ 'nav.invokeError' | translate }}
        </a>
      }
    </menu>
  }

  <main class="main">
    <lib-toasts></lib-toasts>

    <section class="router-outlet">
      @if (ready$ | async) {
        <router-outlet />
      }
    </section>
  </main>
</section>

<footer>
  <!-- „OneTrust Cookie-Einstellungen“ – Schaltfläche – Anfang -->
  <button id="ot-sdk-btn" class="ot-sdk-show-settings cookie-settings btn-tertiary x-small">
    Cookie-Einstellungen
  </button>
  <!-- „OneTrust Cookie-Einstellungen“ – Schaltfläche – Ende -->
</footer>

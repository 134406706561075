import { Component, OnInit } from '@angular/core'

import { ActivatedRoute, Params, Router } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-create-admission',
  standalone: true,
  imports: [],
  templateUrl: './create-admission.component.html',
  styleUrl: './create-admission.component.css',
})
export class CreateAdmissionComponent implements OnInit {
  person = { firstName: '', lastName: '', birthDay: '' }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe((params: Params) => {
      this.person.firstName = params['fn']
      this.person.lastName = params['ln']
      this.person.birthDay = /^\d{2}\.\d{2}\.\d{4}$/.test(params['bd']) ? params['bd'] : ''
    })
  }

  ngOnInit(): void {
    if (!this.person.firstName || !this.person.lastName || !this.person.birthDay) {
      this.router.navigateByUrl('/admissions/unknown', { state: { person: this.person } })
    }
  }
}

// example url
// http://localhost:4200/realms/master/admissions/create?fn=Erika&ln=Musterfrau&bd=01.01.1900&id=PID12345&cn=FID067890&gd=female&cell=015770571155&email=erika.musterfrau@web.de&insuranceName=AOK%20-%20Die%20Gesundheitskasse&insuredNo=A123456789&custom.ZV=Zahnversicherung&custom.Nummer_ZV=A987654321&ward=Kieferchirurgie&room=420&custom.1-Bett-Zimmer=true&custom.2-Bett-Zimmer=false

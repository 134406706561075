<section class="new-admissions">
  <h1 class="headline heading-m m-semibold">
    {{ 'admissions.new.title' | translate }} {{ person.firstName }} {{ person.lastName }} *{{ person.birthDay }}
  </h1>
  <div class="sidebar card-plain">
    <ul class="sidebar-list">
      <li class="sidebar-list_item current">
        <span class="icon"> <app-icon-circle-plus-filled></app-icon-circle-plus-filled> </span>
        <p class="text-xs text">Aufnahmeformulare auswählen</p>
      </li>
      <li class="sidebar-list_item">
        <span class="icon"></span>
        <p class="text-xs text">Aufnahme starten</p>
      </li>
    </ul>
  </div>
  <section class="admission-list">
    <div class="card-plain">
      <h2 class="heading-s m-semibold">Aufnahmeformulare auswählen</h2>
      <h4 class="text-m">Welche Formulare sollen zugewiesen werden?</h4>

      <form action="">
        @for (workflow of workflows; track workflow.id) {
          <mat-checkbox
            class="wokflow-item"
            [checked]="workflow.selected"
            (change)="handleChange($event.checked, workflow)"
          >
            <h3 class="title">
              {{ workflow.title || '-' }}
            </h3>
            <h6 class="title-content">{{ workflow.questionnaires.join(', ') }}</h6>
          </mat-checkbox>
        }
      </form>
    </div>
    <button class="btn-primary" data-testid="start-admission" (click)="startAdmission()" [disabled]="isStartDisabled">
      Aufnahme starten
    </button>
  </section>
</section>

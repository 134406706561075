import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-error-outline',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_838_22605)">
      <path
        d="M11.8462 2C6.33222 2 1.84619 6.48604 1.84619 12C1.84619 17.514 6.33222 22 11.8462 22C17.3602 22 21.8462 17.514 21.8462 12C21.8462 6.48604 17.3602 2 11.8462 2ZM11.8462 3.5C16.5495 3.5 20.3462 7.2967 20.3462 12C20.3462 16.7033 16.5495 20.5 11.8462 20.5C7.14288 20.5 3.34619 16.7033 3.34619 12C3.34619 7.2967 7.14288 3.5 11.8462 3.5ZM11.8345 6.48926C11.6357 6.49236 11.4463 6.57423 11.3079 6.71686C11.1695 6.8595 11.0933 7.05125 11.0962 7.25V13.25C11.0948 13.3494 11.1131 13.4481 11.1502 13.5403C11.1873 13.6325 11.2423 13.7164 11.3121 13.7872C11.3819 13.858 11.465 13.9142 11.5567 13.9526C11.6484 13.9909 11.7468 14.0107 11.8462 14.0107C11.9456 14.0107 12.044 13.9909 12.1357 13.9526C12.2274 13.9142 12.3105 13.858 12.3803 13.7872C12.4501 13.7164 12.5051 13.6325 12.5422 13.5403C12.5792 13.4481 12.5976 13.3494 12.5962 13.25V7.25C12.5976 7.14962 12.5789 7.04997 12.5411 6.95695C12.5033 6.86394 12.4473 6.77946 12.3762 6.70852C12.3052 6.63758 12.2206 6.58161 12.1276 6.54395C12.0345 6.50629 11.9349 6.48769 11.8345 6.48926ZM11.8462 15.5C11.581 15.5 11.3266 15.6054 11.1391 15.7929C10.9515 15.9804 10.8462 16.2348 10.8462 16.5C10.8462 16.7652 10.9515 17.0196 11.1391 17.2071C11.3266 17.3946 11.581 17.5 11.8462 17.5C12.1114 17.5 12.3658 17.3946 12.5533 17.2071C12.7408 17.0196 12.8462 16.7652 12.8462 16.5C12.8462 16.2348 12.7408 15.9804 12.5533 15.7929C12.3658 15.6054 12.1114 15.5 11.8462 15.5Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_838_22605"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCircleErrorOutlineComponent {}

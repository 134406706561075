import { EventEmitter } from '@angular/core'
import { Toast } from '../toasts/toasts.component'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  public itemAdded$: EventEmitter<Toast>

  constructor() {
    this.itemAdded$ = new EventEmitter()
  }

  public add(item: Toast): void {
    if (!item.msg && item.type === 'error') {
      item.msg = 'app.genericError'
    }
    this.itemAdded$.emit(item)
  }
}

import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-circle-plus-filled',
  standalone: true,
  template: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_932_20608)">
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM16.25 12.75H12.75V16.25C12.75 16.6645 12.4145 17 12 17C11.5855 17 11.25 16.6645 11.25 16.25V12.75H7.75C7.3355 12.75 7 12.4145 7 12C7 11.5855 7.3355 11.25 7.75 11.25H11.25V7.75C11.25 7.3355 11.5855 7 12 7C12.4145 7 12.75 7.3355 12.75 7.75V11.25H16.25C16.6645 11.25 17 11.5855 17 12C17 12.4145 16.6645 12.75 16.25 12.75Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_932_20608"><rect width="24" height="24" /></clipPath>
    </defs>
  </svg>`,
})
export class IconCirclePlusFilledComponent {}

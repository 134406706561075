import { createAction, props } from '@ngrx/store'
import { WorkflowEngineEntity } from './workflow-engine.models'
import { QuestionnaireItem, Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'

export const initWorkflowEngine = createAction('[Workflow Page] Init')

export const loadWorkflowEngineSuccess = createAction(
  '[Workflow/API] Load WorkflowEngine Success',
  props<{ workflowEngine: WorkflowEngineEntity[] }>(),
)

export const loadWorkflowEngineFailure = createAction(
  '[Workflow/API] Load WorkflowEngine Failure',
  props<{ error: string | null | undefined }>(),
)

export const loadQuestionnaires = createAction('[Workflow/API] Load Admissions Failure')

export const loadWorkflow = createAction('[Workflow/API] Set Workflow', props<{ id: string; workflow: Workflow }>())

export const setWorkflow = createAction('[Workflow/API] Set Workflow', props<{ workflow: Workflow }>())

export const saveWorkflow = createAction('[Workflow/API] Save Workflow', props<{ workflow: Workflow }>())

export const updateWorkflow = createAction('[Workflow/API] Update Workflow')

export const addQuestion = createAction('[Workflow/API] Add Question', props<{ question: QuestionnaireItem }>())

export const updateQuestion = createAction('[Workflow/API] Update Question', props<{ question: QuestionnaireItem }>())

export const deleteQuestion = createAction('[Workflow/API] Delete Question', props<{ question: QuestionnaireItem }>())

export const editQuestion = createAction('[Workflow/API] Edit Question', props<{ question: QuestionnaireItem }>())

export const updateProperties = createAction(
  '[Workflow/API] Update Propeties',
  props<{ name: string; title: string }>(),
)

import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs'
import * as AdmissionActions from './admissions.actions'
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store'
import { Router } from '@angular/router'
import { AdmissionsService } from '../admissions.service'
import { DataCRUDService } from '../../../generated'
import { select, Store } from '@ngrx/store'
import * as AdmissionsSelectors from './admissions.selectors'
import { AdmissionsEntity } from './admissions.models'

@Injectable()
export class AdmissionsEffects {
  private actions$ = inject(Actions)
  private admissionService = inject(AdmissionsService)
  private crudService = inject(DataCRUDService)
  private router = inject(Router)
  private store = inject(Store)

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdmissionActions.initAdmissions),
      switchMap(() =>
        this.crudService.getDataSet('admission').pipe(
          map((data) => {
            const admissions = data.map((a) => a.body) as AdmissionsEntity[]
            return AdmissionActions.loadAdmissionsSuccess({ admissions })
          }),
          catchError((error) => of(AdmissionActions.createAdmissionsFailure(error))),
        ),
      ),
    ),
  )

  admissionsListRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((navigation) => navigation.payload.routerState.url.startsWith('/admissions')),
      withLatestFrom(this.store.pipe(select(AdmissionsSelectors.selectAllAdmissions))),
      map(([admissions]) => (admissions.length ? AdmissionActions.doNothing() : AdmissionActions.initAdmissions())),
    ),
  )

  createAdmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter(
        ({
          payload: {
            routerState: { url },
          },
        }: RouterNavigationAction) => url.startsWith('/admissions/create'),
      ),
      map(
        ({
          payload: {
            routerState: {
              root: { queryParams },
            },
          },
        }: RouterNavigationAction) => queryParams,
      ),
      // Got HIS/KIS parameters, need to process the data in an admission service before it gets into the store
      switchMap((queryParams) => {
        const admission = this.admissionService.createAdmissionFromQueryParams(queryParams)
        return this.crudService.createData('admission', admission).pipe(
          mergeMap(() =>
            of(AdmissionActions.createAdmissionsSuccess({ admission }), AdmissionActions.initAdmissions()),
          ),
          catchError((error) => of(AdmissionActions.createAdmissionsFailure(error))),
        )
      }),
    ),
  )

  processAdmissionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdmissionActions.createAdmissionsSuccess),
      switchMap(({ admission }) => {
        this.router.navigate(['/admissions/process', admission.id]).then(() => of(AdmissionActions.doNothing()))
        return of(AdmissionActions.doNothing())
      }),
    ),
  )
}

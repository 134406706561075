import { Component, HostListener, inject } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { InputFieldComponent } from '@client-workspace/custom-components'
import { IconEyeShowOutlineComponent } from '@client-workspace/icons/eye_show_outline.component'
import { IconEyeHiddenOutlineComponent } from '@client-workspace/icons/eye_hidden_outline.component'
import { RouterLink } from '@angular/router'
import { AuthFacade } from '../+state/auth.facade'
import { getRealmNameFromUrl } from '../../../-shared/utils/utils'
import { AppFacade } from '../../../+state/app.facade'

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    TranslateModule,
    InputFieldComponent,
    IconEyeShowOutlineComponent,
    IconEyeHiddenOutlineComponent,
    RouterLink,
  ],
  providers: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  auth = inject(AuthFacade)
  app = inject(AppFacade)

  passwordVisible = false
  username = ''
  password = ''

  handleLogin() {
    const realm = getRealmNameFromUrl(window.location.href)
    this.app.notReady()
    this.auth.login({ user: this.username, password: this.password, realm })
  }

  @HostListener('document:keydown', ['$event']) handleKeyboardEvents(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleLogin()
    }
  }
}

import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { IconLogoutFilledComponent } from '@client-workspace/icons/logout_filled.component'
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog'
import { IconCloseFilledComponent } from '@client-workspace/icons/close_filled.component'
import { AuthFacade } from '../+state/auth.facade'

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconLogoutFilledComponent, IconCloseFilledComponent, MatDialogClose],
  template: ` <section class="card">
    <app-icon-close-filled
      style="fill: var(--text-heading-primary)"
      mat-dialog-close
      class="dialog-close-button"
    ></app-icon-close-filled>
    <div class="m-centered">
      <app-icon-logout-filled></app-icon-logout-filled>
    </div>
    <h1 class="heading-m m-semibold m-text-center">{{ 'auth.logout.title' | translate }}</h1>
    <p class="text-m">{{ 'auth.logout.confirmationQuestion' | translate }}</p>
    <button class="btn-primary width-100" (click)="handleLogout()" data-testid="logout">
      {{ 'auth.logout.buttonLabel' | translate }}
    </button>
  </section>`,
  styles: `
    .card {
      max-width: 427px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    app-icon-logout-filled {
      zoom: 2.25;
      fill: var(--color-dark-blue-700);
    }
  `,
})
export class LogoutComponent {
  authFacade = inject(AuthFacade)

  constructor(private dialog: MatDialogRef<LogoutComponent>) {}

  handleLogout() {
    window.localStorage.setItem('logout-event', window.crypto.getRandomValues(new Uint8Array(10)).join(''))
    this.authFacade.logout()
    this.dialog.close()
  }
}

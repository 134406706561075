import { Component, inject } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { MatDialog } from '@angular/material/dialog'
import { LogoutComponent } from '../../../components/auth/logout/logout.component'
import { AuthFacade } from '../../../components/auth/+state/auth.facade'

import { IconHomeFilledComponent } from '@client-workspace/icons/home_filled.component'
import { IconSettingsFilledComponent } from '@client-workspace/icons/settings_filled.component'
import { IconMenuHamburgerOutlineComponent } from '@client-workspace/icons/menu_hamburger_outline.component'
import { IconCloseOutlineComponent } from '@client-workspace/icons/close_outline.component'
import { AppFacade } from '../../../+state/app.facade'
import { SelectFieldComponent } from '@client-workspace/custom-components'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    FormsModule,
    TranslateModule,
    CommonModule,
    IconHomeFilledComponent,
    IconSettingsFilledComponent,
    IconMenuHamburgerOutlineComponent,
    IconCloseOutlineComponent,
    SelectFieldComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  title = 'CaaS'
  selectedLanguage = window.localStorage.getItem('lang') ?? 'de'
  isMobileMenuVisible = false
  isDesktopScreen = false
  app = inject(AppFacade)
  auth = inject(AuthFacade)
  user$ = this.auth.user$

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
  ) {
    this.user$ = this.auth.user$
    document.documentElement.lang = this.selectedLanguage

    this.app.breakpoint$.subscribe((bp) => {
      this.isDesktopScreen = ['xxl', 'xl'].includes(bp)
    })
  }

  onLanguageChange() {
    this.translate.use(this.selectedLanguage)

    window.localStorage.setItem('lang', this.selectedLanguage)
    document.documentElement.lang = this.selectedLanguage
  }

  handleLogout() {
    this.dialog.open(LogoutComponent)
  }

  toggleMenu() {
    this.isMobileMenuVisible = !this.isMobileMenuVisible
  }
}

import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLink } from '@angular/router'
import { MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card'
import { TranslateModule } from '@ngx-translate/core'
import { AdmissionsFacade } from '../+state/admissions.facade'
import { IconEditOutlineComponent } from '@client-workspace/icons/edit_outline.component'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'app-list-admissions',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatTableModule,
    MatCardModule,
    TranslateModule,
    IconEditOutlineComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './list-admissions.component.html',
  styleUrl: './list-admissions.component.scss',
})
export class ListAdmissionsComponent {
  admissionFacade = inject(AdmissionsFacade)

  displayedColumns: string[] = ['status', 'date', 'fn', 'ln', 'ward', 'insuranceName', 'actions']
  dataSource$ = this.admissionFacade.dataSource$
}

<div class="dialog-container">
  <!-- <div [ngClass]="{ darken: requestSent }">
     <div class="inner-dialog">
       <div class="grid-full">
         <h2>
           @if (data.id) {
             <app-icon-edit-filled style="fill: var(&#45;&#45;text-heading-primary)"></app-icon-edit-filled>
             {{ 'userManagement.editUserDialog.title'| translate }}
           } @else {
             <app-icon-plus-filled style="fill: var(&#45;&#45;text-heading-primary)"></app-icon-plus-filled>
             {{ 'userManagement.titleAddUser' | translate }}
           }
         </h2>
         <app-icon-close-filled
           style="fill: var(&#45;&#45;text-heading-primary)"
           mat-dialog-close
           class="dialog-close-button"
         ></app-icon-close-filled>
         <p class="top-text">{{ 'userManagement.editUserForm.description' | translate }}</p>
       </div>

       <cc-input-field
         id="inputFirstName"
         label="{{ 'userManagement.formFieldLabel.firstName' | translate }}*"
         [(value)]="data.firstName"
         (valueChange)="firstNameInputIsDirty = true"
         subtext="{{ firstNameInputIsDirty && !isNameValid(data.firstName) ? ('userManagement.formField.firstNameRequiredHint' | translate) : '' }}"
         [status]="firstNameInputIsDirty && !isNameValid(data.firstName) ? 'error' : undefined"
       ></cc-input-field>

       <cc-input-field
         id="inputLastName"
         label="{{ 'userManagement.formField.lastNameLabel' | translate }}*"
         [(value)]="data.lastName"
         (valueChange)="lastNameInputIsDirty = true"
         subtext="{{ lastNameInputIsDirty && !isNameValid(data.lastName) ? ('userManagement.formField.lastNameRequiredHint' | translate) : '' }}"
         [status]="lastNameInputIsDirty && !isNameValid(data.lastName) ? 'error' : undefined"
       ></cc-input-field>

       <cc-input-field
         id="inputEmail"
         label="{{ 'formFieldLabel.email' | translate }}*"
         type="'email'"
         [(value)]="data.email"
         subtext="{{ wrongEMailPattern ? ('auth.notAValidEmailAddress' | translate) : '' }}"
         (valueChange)="checkEMailPattern()"
         [status]="wrongEMailPattern ? 'error' : undefined"
       ></cc-input-field>

       &lt;!&ndash; TODO: duplicate code, move to component &ndash;&gt;
       <label for="role">{{ 'userManagement.inputField.roleLabel' | translate }}*</label>
       <br />
       <form class="user-form">
         <mat-form-field class="user-input">
           <mat-select
             [(value)]="data.roles"
             [formControl]="rolesFormControl"
             multiple
             placeholder="{{ 'userManagement.selectRoleLabel' | translate }}"
             id="role"
           >
             @for (role of roleList; track role) {
               <mat-option [value]="role">{{ i18nUtility.prefixRole(role) | translate }}</mat-option>
             }
           </mat-select>
         </mat-form-field>
       </form>

       &lt;!&ndash;TODO:
       <mat-checkbox [(ngModel)]="sendUpdate">{{ 'userManagement.editUserForm.sendUpdateEmail' | translate }}</mat-checkbox>
       &ndash;&gt;
       <br />
       <label for="role" class="mandatory">*{{ 'userManagement.formField.mandatory' | translate }}</label>

       <button
         class="btn-primary width-100"
         (click)="updateUser()"
         [disabled]="
         !checkedEMailPattern ||
         !rolesFormControl.getRawValue()?.length ||
         requestSent ||
         !isNameValid(data.firstName) ||
         !isNameValid(data.lastName)
       "
       >
         {{ 'userManagement.editUserForm.saveLabel' | translate }}
       </button>
     </div>
   </div>-->


  <div [ngClass]="{ darken: requestSent }">
    <div class="inner-dialog">

      <!-- HEADLINE -->
      <div class="grid-full">
        <h2>
          @if (user.id) {
            <app-icon-edit-filled style="fill: var(--text-heading-primary)"></app-icon-edit-filled>
            {{ 'userManagement.editUserDialog.title'| translate }}
          } @else {
            <app-icon-plus-filled style="fill: var(--text-heading-primary)"></app-icon-plus-filled>
            {{ 'userManagement.titleAddUser' | translate }}
          }
        </h2>
        <app-icon-close-filled
          style="fill: var(--text-heading-primary)"
          mat-dialog-close
          class="dialog-close-button"
        ></app-icon-close-filled>
        <p class="top-text">{{ 'userManagement.editUserForm.description' | translate }}</p>
      </div>

      <form [formGroup]="userEditFormGroup">

        <!-- FIRST NAME -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'userManagement.formFieldLabel.firstName' | translate }}</mat-label>
          <input matInput
                 id="inputFirstName"
                 [placeholder]="'userManagement.formFieldLabel.firstName' | translate"
                 [formControl]="firstName">
          @if (firstName.invalid && firstName.errors?.required) {
            <mat-error>{{ 'userManagement.formField.firstNameRequiredHint' | translate }}</mat-error>
          }
          @if (firstName.invalid && firstName.errors?.maxlength) {
            <mat-error>{{ 'userManagement.formField.maxLength' | translate }}</mat-error>
          }
        </mat-form-field>

        <!-- LAST NAME -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'userManagement.formField.lastNameLabel' | translate }}</mat-label>
          <input matInput
                 id="inputLastName"
                 [placeholder]="'userManagement.formField.lastNameLabel' | translate"
                 [formControl]="lastName">
          @if (lastName.invalid && lastName.errors?.required) {
            <mat-error>{{ 'userManagement.formField.lastNameRequiredHint' | translate }}</mat-error>
          }
          @if (lastName.invalid && lastName.errors?.maxlength) {
            <mat-error>{{ 'userManagement.formField.maxLength' | translate }}</mat-error>
          }
        </mat-form-field>

        <!-- E-MAIL -->
        <mat-form-field appearance="outline">
          <mat-label>{{ 'formFieldLabel.email' | translate }}</mat-label>
          <input matInput
                 id="inputEmail"
                 [placeholder]="'formFieldLabel.email' | translate"
                 [formControl]="email" />
          @if (email.invalid && email.errors?.required) {
            <mat-error>{{ 'userManagement.formField.emailRequiredHint' | translate }}</mat-error>
          }
          @if (email.invalid && email.errors?.pattern) {
            <mat-error>{{ 'auth.notAValidEmailAddress' | translate }}</mat-error>
          }
          @if (email.errors?.api) {
            <mat-error>{{ apiError | translate }}</mat-error>
          }

        </mat-form-field>

        <!-- ROLES -->
        <mat-form-field class="user-input" appearance="outline">
          <mat-label>{{ 'userManagement.inputField.roleLabel' | translate }}</mat-label>
          <mat-select
            id="role"
            [formControl]="roles"
            multiple
            placeholder="{{ 'userManagement.selectRoleLabel' | translate }}"
          >
            @for (role of roleList; track role) {
              <mat-option [value]="role">{{ i18nUtility.prefixRole(role) | translate }}</mat-option>
            }
          </mat-select>
          @if (roles.invalid && roles.errors?.required) {
            <mat-error>{{ 'userManagement.formField.rolesRequiredHint' | translate }}</mat-error>
          }
        </mat-form-field>

      </form>
      <label class="mandatory">*{{ 'userManagement.formField.mandatory' | translate }}</label>
      <div class="dialog-actions">
        <button class="btn-secondary" mat-dialog-close>
          {{ 'userManagement.editUserForm.cancelLabel' | translate }}
        </button>
        <button
          class="btn-primary"
          (click)="updateUser()"
          [disabled]="userEditFormGroup.invalid"
        >
          {{ 'userManagement.editUserForm.saveLabel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
@if (requestSent) {
  <mat-spinner></mat-spinner>
}

import { Injectable, inject } from '@angular/core'
import { select, Store } from '@ngrx/store'

import * as WorkflowEngineActions from './workflow-engine.actions'
import * as WorkflowEngineSelectors from './workflow-engine.selectors'
import { QuestionnaireItem, Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'

@Injectable()
export class WorkflowEngineFacade {
  private readonly store = inject(Store)

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  state$ = this.store.pipe(select(WorkflowEngineSelectors.selectWorkflowEngineState))
  workflow$ = this.store.pipe(select(WorkflowEngineSelectors.selectWorkflow))
  editQuestion$ = this.store.pipe(select(WorkflowEngineSelectors.selectEditQuestion))
  loaded$ = this.store.pipe(select(WorkflowEngineSelectors.selectWorkflowEngineLoaded))
  allWorkflowEngine$ = this.store.pipe(select(WorkflowEngineSelectors.selectAllWorkflowEngine))

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(WorkflowEngineActions.initWorkflowEngine())
  }

  updateQuestion(question: QuestionnaireItem) {
    this.store.dispatch(WorkflowEngineActions.updateQuestion({ question }))
  }

  loadQuestionnaires() {
    this.store.dispatch(WorkflowEngineActions.loadQuestionnaires())
  }

  loadWorkflow(id: string, workflow: Workflow) {
    this.store.dispatch(WorkflowEngineActions.loadWorkflow({ id, workflow }))
  }

  setWorkflow(workflow: Workflow) {
    this.store.dispatch(WorkflowEngineActions.setWorkflow({ workflow }))
  }

  saveWorkflow(workflow: Workflow) {
    this.store.dispatch(WorkflowEngineActions.saveWorkflow({ workflow }))
  }

  updateWorkflow() {
    this.store.dispatch(WorkflowEngineActions.updateWorkflow())
  }

  editQuestion(question: QuestionnaireItem) {
    this.store.dispatch(WorkflowEngineActions.editQuestion({ question }))
  }

  updateProperties({ name, title }: { name: string; title: string }) {
    this.store.dispatch(WorkflowEngineActions.updateProperties({ name, title }))
  }

  deleteQuestion(question: QuestionnaireItem) {
    this.store.dispatch(WorkflowEngineActions.deleteQuestion({ question }))
  }
}

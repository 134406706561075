import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { EnableWhen, QuestionnaireItem } from '../models/fhir-interfaces'
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { InputFieldComponent, Option, SelectFieldComponent } from '@client-workspace/custom-components'

import { v4 as uuidv4 } from 'uuid'

@Component({
  standalone: true,
  selector: 'app-properties-editor',
  templateUrl: './properties-editor.component.html',
  styleUrls: ['./properties-editor.component.scss'],
  imports: [ReactiveFormsModule, MatInputModule, FormsModule, InputFieldComponent, SelectFieldComponent],
})
export class PropertiesEditorComponent implements OnChanges {
  @Input() selectedItem: QuestionnaireItem | undefined = undefined
  form: FormGroup = this.fb.group({})
  @Input() properties: { name: string; title: string } = { name: '', title: '' }
  @Input() questions: QuestionnaireItem[] = []
  @Input() enableWhenQuestions: Option[] = []
  @Output() deleteQuestion = new EventEmitter<QuestionnaireItem>()
  @Output() updateQuestion = new EventEmitter<QuestionnaireItem>()
  @Output() updateProperties = new EventEmitter<{ name: string; title: string }>()
  enableWhen: EnableWhen[] = []
  enableWhenQuestionsFiltered: Option[] = []
  newOptionText = ''
  newCustomText = ''
  isRequired = false
  enableOperators = [
    {
      label: 'Exists',
      value: 'exists',
    },
    {
      label: 'Equals',
      value: '=',
    },
    {
      label: 'Not Equals',
      value: '!=',
    },
    {
      label: 'Greater Than',
      value: '>',
    },
    {
      label: 'Less Than',
      value: '<',
    },
    {
      label: 'Greater or Equals',
      value: '>=',
    },
    {
      label: 'Less or Equals',
      value: '<=',
    },
  ]

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && this.selectedItem) {
      this.form = this.fb.group({
        text: [this.selectedItem.text],
        required: [this.selectedItem.required ?? false],
      })

      this.newCustomText = this.selectedItem?.text || ''
      this.isRequired = !!this.selectedItem?.required
      this.enableWhen = this.selectedItem?.enableWhen || []

      this.enableWhenQuestionsFiltered = this.enableWhenQuestions.filter((el) => el.value !== this.selectedItem?.linkId)
    }
  }

  onSubmit() {
    if (!this.selectedItem) {
      return
    }
    const item = structuredClone(this.selectedItem)
    item.text = this.newCustomText
    item.required = this.isRequired
    item.enableWhen = this.enableWhen

    this.updateQuestion.emit(item)
    this.resetFields()
  }

  resetFields() {
    this.newOptionText = ''
    this.newCustomText = ''
    this.isRequired = false
  }

  deleteOption(item: QuestionnaireItem) {
    this.deleteQuestion.emit(item)
  }

  setGroupLayout(layout: string) {
    if (this.selectedItem) {
      this.selectedItem.layout = layout
    }

    // this.selectedItem
  }

  addChoiceOrCheckbox() {
    if (!this.selectedItem) {
      return
    }
    this.selectedItem.answerOptions = this.selectedItem.answerOptions || []
    this.selectedItem.answerOptions.push({
      id: uuidv4(),
      valueCoding: {
        display: '',
      },
    })
  }

  markPreselected(id: string) {
    this.selectedItem?.answerOptions?.forEach((el) => {
      el.initialSelected = el.id === id
    })
  }

  saveProperties() {
    this.updateProperties.emit(this.properties)
  }
}

import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card'
import { TranslateModule } from '@ngx-translate/core'
import { Questionnaire } from '../../../questionnaire-builder/models/fhir-interfaces'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule } from '@angular/forms'
import { IconCirclePlusFilledComponent } from '@client-workspace/icons/circle_plus_filled.component'
import { ActivatedRoute, Router } from '@angular/router'

interface WorkflowBody {
  id: string
  title: string
  name: string
  items: Questionnaire[]
}

interface workflowData {
  id: string
  title: string
  name: string
  questionnaires: string[]
  selected: boolean
}
@Component({
  selector: 'app-new-admissions',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatCardModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    IconCirclePlusFilledComponent,
  ],
  templateUrl: './new-admissions.component.html',
  styleUrl: './new-admissions.component.scss',
})
export class NewAdmissionsComponent implements OnInit {
  workflows: workflowData[] = []
  isStartDisabled = true
  person = { firstName: '', lastName: '', birthDay: '' }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.person.firstName = params['fn']
      this.person.lastName = params['ln']
      this.person.birthDay = /^\d{2}\.\d{2}\.\d{4}$/.test(params['bd']) ? params['bd'] : ''
    })
  }
  ngOnInit(): void {
    if (!this.person.firstName || !this.person.lastName || !this.person.birthDay) {
      this.router.navigateByUrl('/admissions/unknown', { state: { person: this.person } })
    }

    this.loadWorkflows()
  }

  async loadWorkflows() {
    try {
      const response = await fetch(`/services/crud-playground/generic/workflows`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()

      this.workflows = data.map((q: { id: string; body: WorkflowBody }) => {
        return {
          id: q.id,
          title: q.body.title,
          name: q.body.name,
          questionnaires: q.body.items.map((questionnaire: Questionnaire) => questionnaire.name),
          selected: false,
        }
      })
      this.isStartDisabled = !this.workflows.filter((el) => el.selected).length
    } catch (error) {
      console.log(error)
    }
  }

  handleChange(checked: boolean, workflow: workflowData) {
    workflow.selected = checked
    this.isStartDisabled = !this.workflows.filter((el) => el.selected).length
  }

  startAdmission() {
    const selectedIds = this.workflows.filter((el) => el.selected).map((el) => el.id)
    console.log(selectedIds)
  }
}

import { createAction, props } from '@ngrx/store'
import { AppEntity, Breakpoint } from './app.models'

export const initApp = createAction('[App Page] Init')

export const loadAppSuccess = createAction('[App/API] Load App Success', props<{ app: AppEntity[] }>())

export const loadAppFailure = createAction('[App/API] Load App Failure', props<{ error: Error }>())

export const userRedirected = createAction('[App Page] User Redirected', props<{ url: string }>())

export const appBreakpointChange = createAction('[App Page] Change App Breakpoint', props<{ breakpoint: Breakpoint }>())

export const sessionReady = createAction('[App Page] Session Ready!')

export const noActiveSessionAvailable = createAction('[App Page] No Active Session Available')

export const sessionTimeoutsSet = createAction('[App Page] Session Timeouts Set')

export const authenticatedUserAvailable = createAction('[App Page] Authenticated User Available')

export const featureFlagSet = createAction('[App Page] Feature Flag Set', props<{ flag: string }>())

export const appReady = createAction('[App Page] App Ready!')

export const appNotReady = createAction('[App Page] App Not Ready!')

<div class="container">
  <app-canvas
    [questionnaire]="questions || []"
    [properties]="properties"
    (saveQuestions)="handleSaveQuestions($event)"
    (updateQuestionnaire)="handleUpdateQuestionnaire()"
    (saveQuestionnaire)="handleSaveQuestionnaire()"
    (editQuestion)="handleEditQuestion($event)"
  ></app-canvas>
  <app-properties-editor
    [selectedItem]="editQuestion"
    [properties]="properties"
    [questions]="questions"
    [enableWhenQuestions]="enableWhenQuestions"
    (deleteQuestion)="handleDeleteQuestion($event)"
    (updateProperties)="handleUpdateProperties($event)"
    (updateQuestion)="handleUpdateQuestion($event)"
  ></app-properties-editor>
</div>

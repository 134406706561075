import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuestionnaireItem } from '../models/fhir-interfaces'
import { QuestionComponent } from '../question/question.component'

@Component({
  selector: 'app-editor-renderer',
  standalone: true,
  imports: [CommonModule, QuestionComponent],
  templateUrl: './editor-renderer.component.html',
  styleUrl: './editor-renderer.component.scss',
})
export class EditorRendererComponent {
  @Input() questions: QuestionnaireItem[] = []
  @Input() title = ''
  @Output() editQuestion = new EventEmitter<QuestionnaireItem>()

  handleEditBtn(event: Event, question: QuestionnaireItem) {
    event.stopPropagation()
    this.editQuestion.emit(question)
  }

  handleEditQuestion(question: QuestionnaireItem) {
    this.editQuestion.emit(question)
  }
}

<div class="toast" class="is-{{ data.type.toLowerCase() }}">
  <header>
    @switch (data.type) {
      @case ('error') {
        <app-icon-circle-close-filled></app-icon-circle-close-filled>
      }
      @case ('warning') {
        <app-icon-circle-error-filled></app-icon-circle-error-filled>
      }
      @case ('success') {
        <app-icon-circle-check-filled></app-icon-circle-check-filled>
      }
      @case ('info') {
        <app-icon-circle-info-filled></app-icon-circle-info-filled>
      }
    }
    <button class="btn-plain toast-close" (click)="handleCloseToast()">
      <app-icon-close-filled class="icon"></app-icon-close-filled>
    </button>
  </header>
  @if (data.title) {
    <span data-testid="title" class="text-s m-semibold">{{ data.title | translate }}</span>
  }
  @if (data.msg) {
    <span data-testid="msg" class="text-s">{{ data.msg | translate }}</span>
  }
  @if (data.label) {
    <span data-testid="label" class="toast-label">{{ data.label | translate }}</span>
  }
</div>

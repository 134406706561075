import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import * as WorkflowEngineActions from './workflow-engine.actions'
import { WorkflowEngineEntity } from './workflow-engine.models'
import { deleteDeeplyNestedObj, replaceDeeplyNestedObj } from '../../../-shared/utils/utils'
import { QuestionnaireItem, Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'

export const WORKFLOW_ENGINE_FEATURE_KEY = 'workflowEngine'

export interface WorkflowEngineState extends EntityState<WorkflowEngineEntity> {
  loaded: boolean // has the WorkflowEngine list been loaded
  id?: string
  workflow?: Workflow
  selectedId?: string | number // which WorkflowEngine record has been selected
  error?: string | null // last known error (if any)
  editQuestion?: QuestionnaireItem
}

export interface WorkflowEnginePartialState {
  readonly [WORKFLOW_ENGINE_FEATURE_KEY]: WorkflowEngineState
}

export const workflowEngineAdapter: EntityAdapter<WorkflowEngineEntity> = createEntityAdapter<WorkflowEngineEntity>()

export const initialWorkflowEngineState: WorkflowEngineState = workflowEngineAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  name: '',
  title: '',
})

const reducer = createReducer(
  initialWorkflowEngineState,
  on(WorkflowEngineActions.initWorkflowEngine, (state) => ({ ...state, loaded: false, error: null })),
  on(WorkflowEngineActions.loadWorkflowEngineSuccess, (state, { workflowEngine }) =>
    workflowEngineAdapter.setAll(workflowEngine, { ...state, loaded: true }),
  ),

  on(WorkflowEngineActions.loadWorkflowEngineFailure, (state, { error }) => ({ ...state, error })),
  on(WorkflowEngineActions.setWorkflow, (state, { workflow }) => {
    return { ...state, workflow }
  }),
  on(WorkflowEngineActions.loadWorkflow, (state, { id, workflow }) => {
    return { ...state, id, workflow }
  }),
  // on(WorkflowEngineActions.addQuestion, (state, { question }) => ({
  //   ...state,
  //   questions: [...state.questions, question],
  // })),
  on(WorkflowEngineActions.editQuestion, (state, { question }) => ({ ...state, editQuestion: question })),
  on(WorkflowEngineActions.updateQuestion, (state, { question }) => {
    const workflow = structuredClone(state.workflow)
    workflow?.items.forEach((questionnaire) => {
      replaceDeeplyNestedObj(questionnaire.item, question, 'linkId')
    })
    return { ...state, workflow, editQuestion: undefined }
  }),
  // on(WorkflowEngineActions.deleteQuestion, (state, { question }) => {
  //   const questions = structuredClone(state.questions)
  //   deleteDeeplyNestedObj(questions, question, 'linkId')
  //   return { ...state, questions, editQuestion: undefined }
  // }),
  on(WorkflowEngineActions.updateProperties, (state, { name, title }) => {
    const workflow = structuredClone(state.workflow!)
    workflow.name = name
    workflow.title = title
    return { ...state, workflow }
  }),

  on(WorkflowEngineActions.saveWorkflow, (state, { workflow }) => {
    fetch('/services/crud-playground/generic/workflows', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(workflow),
    })
    return { ...state, workflow }
  }),
  on(WorkflowEngineActions.updateWorkflow, (state) => {
    fetch(`/services/crud-playground/generic/workflows/${state.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state.workflow),
    })
    return { ...state }
  }),

  on(WorkflowEngineActions.deleteQuestion, (state, { question }) => {
    const workflow = structuredClone(state.workflow!)
    workflow?.items.forEach((questionnaire) => {
      deleteDeeplyNestedObj(questionnaire.item, question, 'linkId')
    })

    return { ...state, workflow }
  }),
)

export function workflowEngineReducer(state: WorkflowEngineState | undefined, action: Action) {
  return reducer(state, action)
}

import { createAction, props } from '@ngrx/store'
import { AdmissionsEntity } from './admissions.models'
import { FhirQuestionnaireResponse } from '@client-workspace/fhir-engine'

export const initAdmissions = createAction('[Admissions Page] Init')

export const loadAdmissionsSuccess = createAction(
  '[Admissions/API] Load Admissions Success',
  props<{ admissions: AdmissionsEntity[] }>(),
)

export const loadAdmissionsFailure = createAction('[Admissions/API] Load Admissions Failure', props<{ error: Error }>())

export const createAdmissionsSuccess = createAction(
  '[Admissions/API] Create Admissions Success',
  props<{ admission: AdmissionsEntity }>(),
)

export const updateAdmission = createAction(
  '[Admissions/API] Update Admission',
  props<{ id: string; updatedQuestionnaireResponse: FhirQuestionnaireResponse }>(),
)

export const createAdmissionsFailure = createAction(
  '[Admissions/API] Create Admissions Failure',
  props<{ error: Error }>(),
)
export const doNothing = createAction('[Admissions/API] Do Nothing!')

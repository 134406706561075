import { Component, Input, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Workflow } from '../../../questionnaire-builder/models/fhir-interfaces'
import { QuestionnaireRendererComponent } from '../questionnaire-renderer/questionnaire-renderer.component'

@Component({
  selector: 'app-workflow-renderer',
  standalone: true,
  imports: [CommonModule, QuestionnaireRendererComponent],
  template: ` <section class="workflow-renderer">
    @if (workflow) {
      @for (questionnaire of workflow.items; track questionnaire.id) {
        <app-questionnaire-renderer
          class="is-outter"
          [questions]="questionnaire.item"
          [title]="questionnaire.title"
        ></app-questionnaire-renderer>
      }
    }
  </section>`,
  styles: `
    .workflow-renderer {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  `,
})
export class WorkflowRendererComponent implements OnInit {
  @Input() id!: string
  workflow: Workflow | undefined

  ngOnInit(): void {
    this.loadWorkflow(this.id)
  }

  async loadWorkflow(id: string) {
    try {
      const response = await fetch(`/services/crud-playground/generic/workflows/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()

      if (data) {
        this.workflow = data
      }
    } catch (error) {
      console.log(error)
    }
  }
}
